<div class="row align-items-center">
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/istituto-superiore-infermieristico-partner-1.png" alt="image">
        </div>
    </div>
    <div class="col-lg-1 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/istituto-superiore-infermieristico-partner-2.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/istituto-superiore-infermieristico-partner-3.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/istituto-superiore-infermieristico-partner-4.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/istituto-superiore-infermieristico-partner-5.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/istituto-superiore-infermieristico-partner-6.png" alt="image">
        </div>
    </div>
    <div class="col-lg-1 col-6 col-sm-3 col-md-3">
        <span style="font-size: 9pt;" class="ms-4 fw-bolder">n° 117</span>
        <div class="single-partner-item">
            <img src="assets/img/partner/Regione Molise.jpg" alt="image">
        </div>
    </div>
</div>
